<template>
  <div class="wrap">
    <div class="content">
      <el-row
        v-loading="loading"
        :element-loading-text="loadingError ? '服务器错误，请稍后再试' : '' "
        :element-loading-spinner="loadingError ? 'el-icon-error' : '' "
        element-loading-background="rgba(0, 0, 0, 0.7)"
      >
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="form-wrap">
            <div class="form">
              <div class="tabs">
                <div
                    class="tab"
                    v-for="item in currencyArray"
                    :key="item.currency"
                    :class="{current: item.currency === currency, other: item.currency !== currency}"
                    @click="changeCurrency(item.currency)"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="item-row">
                <div class="item item-short">
                  <div class="label">折扣价（预计）：</div>
                  <div class="input-box" :class="{error: error.saleAmount}" >
                    <el-input class="input" v-model="saleAmount" clearable @focus="error.saleAmount = false" @input="inputAmount($event, 'saleAmount')">
                      <template slot="prepend">
                        <span>{{ currentSymbol }}</span>
                      </template>
                    </el-input>
                  </div>
                </div>
                <div class="item item-short">
                  <div class="label">成本价：</div>
                  <div class="input-box" :class="{error: error.costAmount}">
                    <el-input class="input" v-model="costAmount" clearable @focus="error.costAmount = false" @input="inputAmount($event, 'costAmount')">
                      <template slot="prepend">
                        <span>{{ currentSymbol }}</span>
                      </template>
                    </el-input>
                  </div>
                </div>
              </div>
              <div class="item-row">
                <div class="item item-short">
                  <div class="label">重量：</div>
                  <div class="input-box" :class="{error: error.weight}">
                    <el-input class="input" v-model="weight" clearable @focus="error.weight = false">
                      <template slot="append">
                        <span>KG</span>
                      </template>
                    </el-input>
                  </div>
                </div>
                <div class="item item-short">
                  <div class="label">头程运费：</div>
                  <div class="input-box" :class="{error: error.headShippingFee}">
                    <el-input class="input" v-model="headShippingFee" clearable  @focus="error.headShippingFee = false" @input="inputAmount($event, 'headShippingFee')">
                      <template slot="append">
                        <span>{{ currentSymbol }}/KG</span>
                      </template>
                    </el-input>
                  </div>
                </div>
              </div>
              <div class="item-row">
                <div class="item item-short">
                  <div class="label">选择店铺声誉：</div>
                  <div class="input-box" :class="{error: error.shopLevelIndex }">
                    <el-select v-model="shopLevelIndex" placeholder="请选择" @focus="error.shopLevelIndex = false">
                      <el-option :label="item.shopLevel" :value="index" v-for="(item, index) in shippingFee" :key="item.shopLevel" ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="item item-short">
                  <div class="label">运费：</div>
                  <div class="input-box" :class="{error: error.shippingType}">
                    <el-select v-model="shippingType" placeholder="请选择" @focus="error.shippingType = false" :disabled="shippingTypeDisabled">
                      <el-option label="包邮" :value="1"></el-option>
                      <el-option label="不包邮" :value="2"></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="item-row">
                <div class="item item-long">
                  <div class="label">选择商品品类：</div>
                  <div class="input-box" :class="{error: error.selectGoodsCategoryRates}">
                    <el-cascader
                      v-model="selectGoodsCategoryRates"
                      :options="goodsCategories"
                      @change="changeGoodsCategory"
                      @focus="cascaderFocus"
                    ></el-cascader>
                  </div>
                </div>
              </div>
              <div class="button-wrap">
                <el-button type="info button" class="reset" @click="reset">重置</el-button>
                <el-button type="warning button" class="calculate" @click="calculate">计算</el-button>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="result-wrap">
            <div class="result">
              <div class="profit">
                <div class="title">单件商品预估利润为：</div>
                <div class="num">
                  <img :src="require('@/assets/images/profit.png')" alt="" />
                  <span v-if="result.profit !== null">
                    {{ currentSymbol }} {{ result.profit }}
                  </span>
                  <span v-if="result.profit === null">
                    {{ currentSymbol }} --
                  </span>
                </div>
              </div>
              <div class="profit-detail" v-if="result.profit !== null">
                <div class="title">明细：</div>
                <div class="item">
                  <div class="dt">商品售价：</div>
                  <div class="dd">{{ currentSymbol }} {{ saleAmount }}</div>
                </div>
                <div class="item">
                  <div class="dt">头程运费：</div>
                  <div class="dd">- {{ currentSymbol }} {{ result.headShippingFee }}</div>
                </div>
                <div class="item">
                  <div class="dt">平台运费（预估）：</div>
                  <div class="dd">- {{ currentSymbol }} {{ result.shopShippingFee }}</div>
                </div>
                <div class="item">
                  <div class="dt">平台佣金（预估）：</div>
                  <div class="dd">- {{ currentSymbol }} {{ result.commissionFee }}</div>
                </div>
                <div class="item">
                  <div class="dt">商品成本：</div>
                  <div class="dd">- {{ currentSymbol }} {{ costAmount }}</div>
                </div>
              </div>
              <div class="profit-result" v-if="result.profit !== null">
                <div class="item">
                  <div class="dt">预估利润 ：</div>
                  <div class="dd">{{ currentSymbol }} {{ result.profit }}</div>
                </div>
              </div>
              <div class="explain" v-if="result.profit === null">
                <div class="title">说明：</div>
                <div class="span">1、仅用于计算单件商品的利润</div>
                <div class="span">
                  <div class="line">2、计算汇率：MXN $ {{ rateMXNText }} = $ {{ rateUSDText }} = ¥ {{ rateCNYText }}</div>
                  <div class="line">汇率每日浮动，计算结果略有误差</div>
                </div>
                <div class="span">3、计算结果仅供参考，实际金额请以美客多订单结算页为准</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import request from "@/utils/request";
import {add, div, mul, sub} from "@/utils/calculation";

// 金额验证正则
const amountRule = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){0,2})?$/;
// 重量验证正则
const weightRule = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){0,4})?$/;
const defaultCurrency = 'MXN';
export default {
  name: 'index',
  data() {
    return {
      currencyArray: [
        {name: '美元', currency: 'USD', symbol: '$'},
        {name: '人民币', currency: 'CNY', symbol: '¥'},
        {name: '墨西哥比索', currency: 'MXN', symbol: 'MXN $'},
      ],
      currency: defaultCurrency,

      // 输入参数
      saleAmount: '',
      costAmount: '',
      weight: '',
      headShippingFee: '',
      shopLevelIndex: '',
      shippingType: '',
      selectGoodsCategoryRates: [],
      // 金额集合参数，按用户输入的固定金额
      amountValues: {
        saleAmount: {
          USD: '',
          CNY: '',
          MXN: '',
          inputCurrent: defaultCurrency
        },
        costAmount: {
          USD: '',
          CNY: '',
          MXN: '',
          inputCurrent: defaultCurrency
        },
        headShippingFee: {
          USD: '',
          CNY: '',
          MXN: '',
          inputCurrent: defaultCurrency
        },
      },

      // UI展示
      options: [],
      loading: false,
      loadingError: false,
      shippingTypeDisabled: false,

      // 计算的数据
      rates: null,
      commissionLimit: 0,
      commissionSmallerFee: 0,
      commission: [],
      shippingFee: [],
      weightFee: [],

      // 计算结果
      result: {
        commissionFee: null,
        headShippingFee: null,
        shopShippingFee: null,
        shippingFee: null,
        profit: null,
      },

      // 计算结果的转换集合
      resultArray: {
        commissionFee: {
          USD: null,
          CNY: null,
          MXN: null,
          calculateCurrency: null,
        },
        headShippingFee: {
          USD: null,
          CNY: null,
          MXN: null,
          calculateCurrency: null,
        },
        shopShippingFee: {
          USD: null,
          CNY: null,
          MXN: null,
          calculateCurrency: null,
        },
        shippingFee: {
          USD: null,
          CNY: null,
          MXN: null,
          calculateCurrency: null,
        },
        profit: {
          USD: null,
          CNY: null,
          MXN: null,
          calculateCurrency: null,
        },
      },

      // 输入错误提示
      error: {
        saleAmount: false,
        costAmount: false,
        weight: false,
        headShippingFee: false,
        shopLevelIndex: false,
        shippingType: false,
        selectGoodsCategoryRates: false,
      }
    }
  },
  computed: {
    goodsCategories: function () {
      const options = [];
      this.commission.forEach((item, k) => {
        const ii = {
          value: k,
          label: item.name,
        }
        if (item.sub) {
          ii.children = [];
          item.sub.forEach((subItem, kk) => {
            ii.children.push({
              value: kk,
              label: subItem.name,
            })
          })
        }
        options.push(ii);
      })

      return options;
    },
    currentSymbol: function () {
      let symbol = '$';
      for (let i = 0; i < this.currencyArray.length; i++) {
        if (this.currency === this.currencyArray[i].currency) {
          symbol = this.currencyArray[i].symbol;
          break;
        }
      }
      return symbol;
    },
    rateMXNText: function () {
      let amount = '';
      if (this.rates) {
        amount = 100;
      }
      return amount;
    },
    rateUSDText: function () {
      let amount = '';
      if (this.rates) {
        amount = div(mul(100, this.rates.USD, 6), this.rates.MXN, 2);
      }
      return amount;
    },
    rateCNYText: function () {
      let amount = '';
      if (this.rates) {
        amount = div(mul(100, this.rates.CNY, 6), this.rates.MXN, 2);
      }
      return amount;
    }
  },
  watch: {
    saleAmount: function (value) {
      console.log(value, amountRule.test(value));
      if (value !== '' && amountRule.test(value) && this.rates) {
        // 根据输入的销售金额修改包邮
        const commissionLimitC = this.currencyConvert(this.commissionLimit);
        if (value >= commissionLimitC) {
          this.shippingTypeDisabled = true;
          this.shippingType = 1;
        } else {
          this.shippingTypeDisabled = false;
        }
      } else {
        // 根据输入的销售金额修改包邮
        this.shippingTypeDisabled = false;
      }
    },
  },
  created() {
    this.getCalculatorParams();
  },
  methods: {
    // 切换商品的品类
    changeGoodsCategory(value) {

    },
    // 获取计算参数
    getCalculatorParams() {
      this.loading = true;

      // 兼容后台接口
      const url = (process.env.VUE_APP_CALCULATE_API !== undefined ? process.env.VUE_APP_CALCULATE_API : process.env.VUE_APP_BASE_API ) + '/calculator/params';
      request({
        url: url,
      })
        .then(res => {
          if (res.error === 0) {
            console.log('res.data', res.data)
            if (!res.data.rates) {
              this.loadingError = true;
            } else {
              this.loading = false;
              this.rates = res.data.rates.rates;
              this.commissionLimit = res.data.commissionLimit;
              this.commissionSmallerFee = res.data.commissionSmallerFee;
              this.commission = res.data.commission;
              this.shippingFee = res.data.shippingFee;
              this.weightFee = res.data.weightFee;
            }
          } else {
            this.loadingError = true;
            console.log('请求计算参数错误', res.message);
          }
        })
        .catch(error => {
          this.loadingError = true;
          console.log('请求计算参数错误', error);
        })
    },
    // 重置数据
    reset() {
      this.saleAmount = '';
      this.costAmount = '';
      this.weight = '';
      this.headShippingFee = '';
      this.shopLevelIndex = '';
      this.shippingType = '';
      this.selectGoodsCategoryRates = [];
      this.result = {
        commissionFee: null,
        headShippingFee: null,
        shopShippingFee: null,
        shippingFee: null,
        profit: null,
      };
      this.error = {
        saleAmount: false,
        costAmount: false,
        weight: false,
        headShippingFee: false,
        shopLevelIndex: false,
        shippingType: false,
        selectGoodsCategoryRates: false,
      };
    },
    // 计算
    calculate() {
      let check = true;

      // 验证是否填写了数据
      ['saleAmount', 'costAmount', 'weight', 'headShippingFee', 'shopLevelIndex', 'shippingType'].forEach(param => {
        if (this[param] === '') {
          this.error[param] = true;
          check = false;
        }
      });
      if (!check) {
        this.$errorMessage('请填写或选择计算数据');
        return false;
      }
      if (!this.selectGoodsCategoryRates.length) {
        this.error.selectGoodsCategoryRates = true;
        this.$errorMessage('请选择商品品类');
        return false;
      }

      // 验证金额是否正确
      ['saleAmount', 'costAmount', 'headShippingFee'].forEach(param => {
        if (!amountRule.test(this[param])){
          this.error[param] = true;
          check = false;
        }
      });
      if (!check) {
        this.$errorMessage('请填写正确的金额数值，最多两位小数');
        return false;
      }

      // 验证重量是否正确
      if (!weightRule.test(this.weight)) {
        this.error.weight = true;
        this.$errorMessage('请填写正确的重量数值，最多4位小数');
        return false;
      }

      // 计算平台佣金
      let commissionItem = JSON.parse(JSON.stringify(this.commission));
      this.selectGoodsCategoryRates.forEach((index, i) => {
        // console.log(index, i);
        if (i === 0) {
          commissionItem = commissionItem[index];
        } else {
          commissionItem = commissionItem.sub[index];
        }
      })
      const commissionRate = commissionItem.rate;
      console.log('佣金比例: ' + commissionRate);
      const commissionLimitC = this.currencyConvert(this.commissionLimit);
      const commissionSmallerFeeC = this.currencyConvert(this.commissionSmallerFee);
      console.log('佣金界限: ' + commissionLimitC);
      console.log('最低佣金: ' + commissionSmallerFeeC);
      let commissionC = 0;
      if (this.saleAmount >= commissionLimitC) {
        commissionC = mul(this.saleAmount, commissionRate);
      } else {
        commissionC = add(mul(this.saleAmount, commissionRate), commissionSmallerFeeC);
      }
      console.log('平台佣金: ' + commissionC);

      // 计算平台运费
      let shippingRate = 0;
      const shopLevel = this.shippingFee[this.shopLevelIndex];
      if (this.saleAmount >= commissionLimitC) {
        shippingRate = shopLevel['greaterRate'];
      } else {
        shippingRate = shopLevel['smallerRate'];
      }
      console.log('运费比例: ' + shippingRate);
      let calculateBaseWeightFee = 0;
      for (let i = 0; i < this.weightFee.length; i++) {
        let item = this.weightFee[i];
        if (item.max === null) {
          calculateBaseWeightFee = item.fee;
          break;
        }
        if (this.weight >= item.min && this.weight < item.max){
          calculateBaseWeightFee = item.fee;
          break;
        }
      }
      console.log('基础运费(墨西哥比索): ' + calculateBaseWeightFee);
      const calculateBaseWeightFeeC = this.currencyConvert(calculateBaseWeightFee);
      console.log('基础运费: ' + calculateBaseWeightFeeC);
      let calculateWeightFeeC = 0;
      // 包邮加上平台运费，不包邮平台运费为0
      if (this.shippingType === 1) {
        calculateWeightFeeC = mul(calculateBaseWeightFeeC, shippingRate);
      } else {
        calculateWeightFeeC = 0;
      }
      console.log('平台运费: ' + calculateWeightFeeC);
      const headWeightFeeC = mul(this.weight, this.headShippingFee);
      console.log('头程运费: ' + headWeightFeeC);
      const goodsWeightFeeC = add(calculateWeightFeeC, headWeightFeeC);

      console.log('运费: ' + goodsWeightFeeC);

      // 利润
      const profit = sub(this.saleAmount, add(commissionC, add(goodsWeightFeeC, this.costAmount)));
      this.result.commissionFee = commissionC;
      this.result.headShippingFee = headWeightFeeC;
      this.result.shopShippingFee = calculateWeightFeeC;
      this.result.shippingFee = goodsWeightFeeC;
      this.result.profit = profit;

      Object.keys(this.resultArray).forEach(key => {
        this.currencyArray.forEach(item => {
          const currency = item.currency;
          this.resultArray[key][currency] = this.currencyConvert(this.result[key], this.currency, currency);
        })
        this.resultArray[key].calculateCurrency = this.currency;
      })

      console.log('利润: ' + profit);
    },
    // 级联选择器
    cascaderFocus() {
      this.error.selectGoodsCategoryRates = false;
      if (this.selectGoodsCategoryRates.length) {
        this.selectGoodsCategoryRates = [];
      }
    },
    // 修改汇率
    changeCurrency(currency) {
      let check = true;
      // 验证金额是否正确
      ['saleAmount', 'costAmount', 'headShippingFee'].forEach(param => {
        if (this[param] !== '' && !amountRule.test(this[param])){
          this.error[param] = true;
          check = false;
        }
      });
      if (!check) {
        this.$errorMessage('金额数据错误，最多两位小数');
        return false;
      }
      const oldCurrency = this.currency;
      this.currency = currency;

      // 修改填写的金额数据和计算结果数据
      ['saleAmount', 'costAmount', 'headShippingFee'].forEach(param => {
        if (this[param] !== ''){
          // 根据 amountValues 变量修改
          this[param] = this.amountValues[param][this.currency];
          // this[param] = this.currencyConvert(this[param], oldCurrency, this.currency);
        }
      });

      // 计算结果转换
      Object.keys(this.resultArray).forEach(key => {
        this.result[key] = this.resultArray[key][this.currency];
      })
    },
    // 汇率转换
    currencyConvert(amount, fromCurrency = 'MXN', toCurrency = null) {
      let convertAmount = amount;
      if (toCurrency === null) {
        toCurrency = this.currency;
      }
      if (this.rates) {
        convertAmount = div(mul(convertAmount, this.rates[toCurrency], 6), this.rates[fromCurrency], 2);
      }
      return convertAmount;
    },
    // 金额输入时转换
    inputAmount(value, type) {
      if (value !== '' && amountRule.test(value) && this.rates) {
        this.currencyArray.forEach(item => {
          const currency = item.currency;
          if (currency === this.currency) {
            this.amountValues[type][this.currency] = value;
          } else {
            this.amountValues[type][currency] = this.currencyConvert(value, this.currency, currency);
          }
        })
        this.amountValues[type].inputCurrent = this.currency;
      } else {
        this.currencyArray.forEach(item => {
          const currency = item.currency;
          this.amountValues[type][currency] = value;
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  max-width: 820px;
  background: #ffffff;
  min-height: 100%;
  margin: 0 auto;
  padding: 2.3rem 0;
  .content{
    background-color: #f9f9fa;
  }
}
.form-wrap{
  padding: 2rem 0 2rem 2rem;
  display: flex;
  .form{
    flex: 1 1 auto;
    .tabs{
      display: flex;
      justify-content: space-around;
      background-color: #ffffff;
      padding: .6rem .3rem;
      border-radius: 4px;
      font-size: 1rem;
      .tab{
        //border: 1px solid #e4e7ed;
        padding: .3rem .8rem;
        border-radius: 12px;
        &.current{
          background-color: #fefefe;
          border: 1px solid #e4e7ed;
          box-shadow:inset 0px 2px 2px 1px rgba(170, 170, 170, .3);
        }
        &.other{
          cursor: pointer;
        }
      }
    }
  }
  .line{
    padding-right: 2rem;
    border-right: 1px solid #d7d7d7;
  }
}
.item-row{
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 0 0;
  text-align: left;
  .item{
    &.item-short{
      width: 47%;
    }
    &.item-long{
      width: 100%;
    }
    .label{
      padding: 0 0 .2rem;
    }
    .input-box{
      .input{

      }
      span{
        text-align: center;
        font-size: .8rem;
        font-weight: bold;
      }
    }
  }
}
.button-wrap{
  margin: 1rem 0 0;
  padding: 1.6rem 0 0;
  display: flex;
  justify-content: space-between;
  .button{
    font-size: 1.2rem;
    padding: 8px 15px;
  }
  .reset{
    width: 23%;
  }
  .calculate{
    width: 65%;
  }
}
.result-wrap{
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  padding: 2rem;
  .result{
    text-align: left;
    .profit{
      border-bottom: 1px solid #d7d7d7;
      padding: 0 0 1.6rem;
      .title{
        font-size: 1.2rem;
        font-weight: bold;
      }
      .num{
        display: flex;
        height: 8.2rem;
        padding: 1.6rem 0;
        img{
          width: 6.2rem;
          height: 5.6rem;
        }
        span{
          line-height: 5rem;
          margin-left: 2.5rem;
          font-size: 2.2rem;
          color: #F89D3A;
        }
      }
    }
    .profit-detail{
      padding: 1.4rem 0 1rem;
      border-bottom: 1px solid #d7d7d7;
      .title{
        font-size: 1.2rem;
        font-weight: bold;
        padding: .6rem 0;
      }
      .item{
        width: 82%;
        display: flex;
        justify-content: space-between;
        color: #aaaaaa;
        font-weight: normal;
        padding: .3rem 0;
      }
    }
    .profit-result{
      font-size: 1.18rem;
      color: #000000;
      font-weight: bold;
      padding: .8rem 0;
      .item{
        width: 82%;
        display: flex;
        justify-content: space-between;
      }
    }
    .explain{
      .title{
        font-size: 1.2rem;
        font-weight: bold;
        padding: .8rem 0;
      }
      .span{
        color: #aaaaaa;
        padding: .3rem 0;
      }
    }
  }
}




@media screen and (max-width: 768px) {
  .wrap{
    padding: 0 0;
  }
  .form-wrap{
    .line{
      border-right: none;
    }
  }
}
</style>

<style lang="less">
  .input-box{
    .input{
      input{
        padding-left: .3rem;
        font-size: 1rem;
      }
    }
    .el-cascader{
      display: block;
    }
    .el-input-group__prepend{
      background-color: #ffffff;
      padding: 0 .5rem;

    }
    .el-input-group__append{
      background-color: #ffffff;
      padding: 0 .3rem;
    }
    &.error{
      input{
        border-color: #f56c6c;
      }
    }
  }
</style>
