import NP from 'number-precision'

// 小数运算精度问题的封装，精度保留两位小数

// 加
export function add(num1, num2, scale = 2) {
  return NP.round(NP.plus(num1, num2), scale);
}

// 减
export function sub(num1, num2, scale = 2) {
  return NP.round(NP.minus(num1, num2), scale);
}

// 乘
export function mul(num1, num2, scale = 2) {
  return NP.round(NP.times(num1, num2), scale);
}

// 除
export function div(num1, num2, scale = 2) {
  return NP.round(NP.divide(num1, num2), scale);
}

