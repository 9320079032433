import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 全局样式
import '@/styles/base.less';
import request from "@/utils/request";

// 全局引入element 样式
import 'element-ui/lib/theme-chalk/display.css';
// 部分引入 elementUI的组件
import { Button, Select, Option, OptionGroup,  Row, Col, Cascader, CascaderPanel, Message, Input, Form, FormItem, Loading} from 'element-ui';
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.use(Button)
  .use(Select)
  .use(Option)
  .use(OptionGroup)
  .use(Row)
  .use(Col)
  .use(Cascader)
  .use(CascaderPanel)
  .use(Input)
  .use(Form)
  .use(FormItem)
  .use(Loading.directive)

Vue.config.productionTip = false

Vue.prototype.$loading = Loading.service;

// 请求封装
Vue.prototype.$request = opt => {
  return request(opt);
}

// 信息的封装
Vue.prototype.$Message = opt => {
  Message(opt);
}
Vue.prototype.$errorMessage = (message, duration = 3000) => {
  Message({
    message,
    duration,
    type: 'error',
  })
}
Vue.prototype.$successMessage = (message, duration = 3000) => {
  Message({
    message,
    duration,
    type: 'success',
  })
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
